import React, { useEffect, useState } from 'react'
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, Sort, Toolbar } from '@syncfusion/ej2-react-grids'
import { MDBCol, MDBRow } from 'mdbreact'
import apiCall from '../apiCall'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'

function ConsultantsReports() {

    const [ tableData, setTableData ] = useState()
    const [ tableFilter, setTableFilter] = useState('onBench')
    const [ afterDate, setAfterDate] = useState(false)
    const [ afterDateGrid, setAfterDateGrid ] = useState(false)

    const getTableData = async(filter) => {
        if(filter === 'onBench')
            var apiBody = { "onBench": "True" }
        else if(filter === 'Internal')
            var apiBody = { "internalProject": "True" }
        else if(filter === 'External')
            var apiBody = { "externalProject": "True" }
        else return
        var response = await apiCall(apiBody)
        var consultantsList = []
        for (var i=0; i < response.length; i++) {
            consultantsList.push({
                name: response[i].name,
                email: response[i].email,
                onBench: response[i].on_Bench,
                client: response[i].client,
                project: response[i].projectname,
                priority: response[i].projectpriority,
                startDate: new Date(response[i].projectstartdate),
                endDate: new Date(response[i].projectenddate)
            })
        }
        setTableFilter(filter)
        setTableData(consultantsList)
    }

    const onFilterChagne = (e) => {
        e.preventDefault()
        var filter = document.getElementById('filterBy').value
        if(filter !== 'afterDate'){
            setAfterDate(false)
            getTableData(filter)
        }
        else {
            setAfterDate(true)
            setTableFilter(filter)
        }
    }

    const onAfterDateChange = async() => {
        var date = document.getElementById('afterDateDTPicker').value
        if(date == '')
            return
        var apiBody = {"getDetails":"True","particulardate": date}
        var response = await apiCall(apiBody)
        var consultantsList = []
        console.log(response)
        console.log(consultantsList)
        for (var i=0; i < response.length; i++) {
            consultantsList.push({
                name: response[i].name,
                email: response[i].email,
                client: response[i].client,
                project: response[i].projectName,
                type: response[i].projectType,
                startDate: new Date(response[i].projectstartdate),
                endDate: new Date(response[i].projectenddate)
            })
        }
        setAfterDateGrid(true)
        setTableData(consultantsList)
    }

    useEffect(() => {
        getTableData('onBench')
    }, [])

    const toolbarOptions = ['Search', 'Print']

    return (
        <div style={{ marginTop: '3%', backgroundColor:""}}>
            <div className="d-flex justify-content-center mb-3">
                <MDBRow middle>
                    <MDBCol>
                        <select
                            className="browser-default custom-select"
                            title="Filter By"
                            name="filterBy"
                            id="filterBy"
                            onChange={onFilterChagne}
                            value={tableFilter}
                        >
                            <option value="onBench">On Bench</option>
                            <option value="Internal">Internal</option>
                            <option value="External">External</option>
                            <option value="afterDate">Available After Date</option>
                        </select>
                    </MDBCol>
                    {afterDate &&
                        <>
                            <MDBCol>
                                <DatePickerComponent id="afterDateDTPicker" allowEdit={false} openOnFocus={true} onChange={onAfterDateChange} format="dd-MM-yyyy" />
                            </MDBCol>
                        </>
                    }
                </MDBRow>
            </div>
            <GridComponent
                dataSource={tableData}
                toolbar={toolbarOptions}
                allowPaging={true}
                pageSettings={{pageSize: 50,pageSizes: [50,100,150,200], pageCount: 5}}
                allowTextWrap={true}
                allowFiltering={true}
                showColumnChooser={true}
                allowExcelExport={true}
                allowSorting={true}
                allowMultiSorting={true}
                allowPdfExport={true}
                allowResizing={true}
                searchSettings={
                    {fields: ['empId', 'name', 'designation', 'l1Manager', 'l2Manager'],
                    ignoreCase: true,
                    operator: 'contains'}
                }
                sortSettings={{columns: [{ field: 'name', direction: 'Ascending' }]}}
                filterSettings={{type: 'CheckBox'}}
            >
                {tableFilter === 'onBench' &&
                    <ColumnsDirective>
                        <ColumnDirective field='name' headerText='Name' textAlign='Left'/>
                        <ColumnDirective field='email' headerText='Email' textAlign='Left'/>
                        <ColumnDirective field='onBench' headerText="On Bench From(in days)" textAlign='Left' />
                    </ColumnsDirective>
                }
                {tableFilter === 'Internal' &&
                    <ColumnsDirective>
                        <ColumnDirective field='name' headerText='Name' textAlign='Left'/>
                        <ColumnDirective field='email' headerText='Email' textAlign='Left'/>
                        <ColumnDirective field='project' headerText="Project" textAlign='Left'/>
                        <ColumnDirective field='priority' headerText="Priority" textAlign='Left' width={150}/>
                        <ColumnDirective field='startDate' headerText="Start Date" format={{type:'date', format:'dd/MM/yyyy'}} textAlign='Left' width={120} />
                        <ColumnDirective field='endDate' headerText="End Date" format={{type:'date', format:'dd/MM/yyyy'}} textAlign='Left' width={120} />
                    </ColumnsDirective>
                }
                {tableFilter === 'External' &&
                    <ColumnsDirective>
                        <ColumnDirective field='name' headerText='Name' textAlign='Left'/>
                        <ColumnDirective field='email' headerText='Email' textAlign='Left'/>
                        <ColumnDirective field='client' headerText="Client" textAlign='Left'/>
                        <ColumnDirective field='project' headerText="Project" textAlign='Left'/>
                        <ColumnDirective field='priority' headerText="Priority" textAlign='Left'/>
                        <ColumnDirective field='startDate' headerText="Start Date" format={{type:'date', format:'dd/MM/yyyy'}} textAlign='Left' width={120} />
                        <ColumnDirective field='endDate' headerText="End Date" format={{type:'date', format:'dd/MM/yyyy'}} textAlign='Left' width={120} />
                    </ColumnsDirective>
                }
                {afterDateGrid &&
                    <ColumnsDirective>
                        <ColumnDirective field='name' headerText='Name' textAlign='Left'/>
                        <ColumnDirective field='email' headerText='Email' textAlign='Left'/>
                        <ColumnDirective field='client' headerText="Client" textAlign='Left'/>
                        <ColumnDirective field='project' headerText="Project" textAlign='Left'/>
                        <ColumnDirective field='type' headerText="Type" textAlign='Left' width={100}/>
                        <ColumnDirective field='startDate' headerText="Start Date" format={{type:'date', format:'dd/MM/yyyy'}} textAlign='Left' width={120}/>
                        <ColumnDirective field='endDate' headerText="End Date" format={{type:'date', format:'dd/MM/yyyy'}} textAlign='Left' width={120}/>
                    </ColumnsDirective>
                }
                <Inject services={[Toolbar, Page, Sort, Filter]}/>
            </GridComponent>
        </div>
    )
}

export default ConsultantsReports
