import React, { useState } from 'react'
import './Navbar.css'
import Logo from '../assets/logo_white.png'
import * as Msal from 'msal'
import { msalConfig, loginRequest } from '../config'
import { useStateValue } from '../StateProvider'
import { MDBCollapse, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBNavItem, MDBNavLink } from 'mdbreact'
const myMSALObj = new Msal.UserAgentApplication(msalConfig)

function Navbar() {
    const [collapseID, setcollapseID] = useState('')
    const [ { user }, dispatch ] = useStateValue()

    const toggleCollapse = (e) => {
        if(collapseID!=e){
            setcollapseID(e)
        } else {
            setcollapseID('')
        }
    }

    const closeCollapse = (collID) => {
        window.scroll(0, 0)
        if(collapseID === collID)
            setcollapseID('')
    }

    const signOut = () => {
        myMSALObj.logout()
        dispatch({
            type: 'REMOVE_USER'
        })
    }
    const signIn = () => {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");
        const msie11 = ua.indexOf("Trident/");
        const msedge = ua.indexOf("Edge/");
        const isIE = msie > 0 || msie11 > 0;
        let signInType = isIE ? "Redirect" : "Popup"
        if(signInType === "Popup") {
            myMSALObj.loginPopup(loginRequest)
            .then(LoginResponse => {
                dispatch({
                    type: 'SET_USER',
                    user: myMSALObj
                })
            })
            .catch(function (error) {
                alert(error.message)
            })
        } else {
            myMSALObj.loginRedirect(loginRequest)
            .then(LoginResponse => {
                dispatch({
                    type: 'SET_USER',
                    user: myMSALObj
                })
            })
            .catch(function (error) {
                alert(error.message)
            })
        }
    }
    return (
        <MDBNavbar style={{background: "#2f3d5e"}} dark expand="md" fixed="top" scrolling>
            <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
                <img src={Logo} style={{width: '90px'}} />
            </MDBNavbarBrand>
            <MDBNavbarToggler style={{color: 'white'}} onClick={ () => toggleCollapse('mainNavbarCollapse')}/>
            <MDBCollapse style={{backgroundColor:"#2f3d5e"}} id="mainNavbarCollapse"  isOpen={collapseID} navbar>
                <MDBNavbarNav right style={{marginTop: "1.4%"}}>
                    {user ?
                        <>
                            {/* <MDBNavItem onClick={ () => closeCollapse("mainNavbarCollapse")}>
                                <MDBNavLink style={{ color: '#ECF0F1' }} to="/trainingCalendar"><i className="fa fa-calendar-alt"></i> Calendar</MDBNavLink>
                            </MDBNavItem> */}
                            <MDBNavItem onClick={ () => closeCollapse("mainNavbarCollapse")}>
                                <MDBNavLink style={{ color: '#ECF0F1', backgroundColor: 'transparent' }} to="/" > <MDBIcon icon="home" /> Home</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBDropdown>
                                    <MDBDropdownToggle nav caret>
                                        <MDBIcon icon="users" /> Consultants
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu style={{backgroundColor:"#2f3d5e"}}>
                                        <MDBDropdownItem className="dropdownItem" href="/consultants" onClick={ () => closeCollapse("mainNavbarCollapse")}>Consultants</MDBDropdownItem>
                                        <MDBDropdownItem className="dropdownItem" href="/resources" onClick={ () => closeCollapse("mainNavbarCollapse")}>Resources</MDBDropdownItem>
                                        <MDBDropdownItem className="dropdownItem" href="/consultantsreports" onClick={ () => closeCollapse("mainNavbarCollapse")}>Reports</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBDropdown>
                                    <MDBDropdownToggle nav caret>
                                        <MDBIcon icon="chalkboard-teacher" /> Projects
                                    </MDBDropdownToggle>
                                    <MDBDropdownMenu style={{backgroundColor:"#2f3d5e"}}>
                                        <MDBDropdownItem className="dropdownItem" href="/projects" onClick={ () => closeCollapse("mainNavbarCollapse")}>Projects</MDBDropdownItem>
                                        {/* <MDBDropdownItem className="dropdownItem" href="/leaverequests" onClick={ () => closeCollapse("mainNavbarCollapse")}>Leave Requests</MDBDropdownItem>
                                        <MDBDropdownItem className="dropdownItem" href="/skillmap" onClick={ () => closeCollapse("mainNavbarCollapse")}>Skill MAP</MDBDropdownItem>
                                        <MDBDropdownItem className="dropdownItem" href="/utilization" onClick={ () => closeCollapse("mainNavbarCollapse")}>Utilization</MDBDropdownItem> */}
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavItem>
                            <MDBNavItem onClick={ () => closeCollapse("mainNavbarCollapse")}>
                                <MDBNavLink style={{ color: '#ECF0F1', backgroundColor: 'transparent' }} to="/" onClick={ () => signOut() }> <MDBIcon icon="sign-out-alt" /> Logout</MDBNavLink>
                            </MDBNavItem>
                        </>
                    :
                        <MDBNavItem onClick={ () => closeCollapse("mainNavbarCollapse")}>
                            <MDBNavLink style={{ color: '#ECF0F1', backgroundColor: 'transparent' }} to="/" onClick={ () => signIn() }> <MDBIcon icon="sign-in-alt" /> LogIn</MDBNavLink>
                        </MDBNavItem>
                    }
                </MDBNavbarNav>
            </MDBCollapse>
        </MDBNavbar>
    )
}

export default Navbar
