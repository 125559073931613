import React from 'react'
import { config } from './config'

async function apiCall(apiBody) {
    return await fetch(config.apiURL, { method: "POST", body: JSON.stringify(apiBody)})
    .then(response => response.json())
    .then(response => {
        return response
    })
    .catch(error => {
        console.error(error)
    })
}

export default apiCall
