import * as MSAL from 'msal'
import { useEffect } from 'react';
import {msalConfig} from './config'
import { useStateValue } from './StateProvider';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { MDBFooter } from 'mdbreact';
import Dashboard from './Pages/Dashboard';
import Navbar from './Pages/Navbar';
import Consultants from './Pages/Consultants';
import Projects from './Pages/Projects';
import ConsultantsReports from './Pages/ConsultantsReports';
import Resources from './Pages/Resources';


const myMSALObj = new MSAL.UserAgentApplication(msalConfig)

function App() {

  const [{}, dispatch] = useStateValue()

  useEffect(() => {
    if(myMSALObj.getAccount()) {
      dispatch({
        type: 'SET_USER',
        user: myMSALObj.getAccount()
      })
    } else {
      dispatch({
        type: 'SET_USER',
        user: null
      })
    }
  }, [])

  return (
    <Router>
        <div className="flyout">
          <Navbar />
          <main style={{ marginTop: '4rem' }}>
            {myMSALObj.getAccount() ?
            <Switch>
              <Route exact path="/"><Dashboard /></Route>
              <Route exact path="/consultants"><Consultants /></Route>
              <Route exact path="/projects"><Projects /></Route>
              <Route exact path="/consultantsreports"><ConsultantsReports /></Route>
              <Route exact path="/resources"><Resources /></Route>


              {/* <Route exact path="/trainingCalendar"><TrainingCalendar /></Route>
              <Route exact path="/unscheduledrequests"><DisplayTrainings /></Route>
              <Route exact path="/requests"><DisplayTrainings /></Route> 
              <Route exact path="/cancelledrequests"><DisplayTrainings /></Route> 
              <Route exact path="/confirmedrequests"><DisplayTrainings /></Route> 
              <Route exact path="/releasedrequests"><DisplayTrainings /></Route> 
              <Route exact path="/tentativerequests"><DisplayTrainings /></Route>
              <Route exact path="/leaverequests"><DisplayLeaves /></Route>
              <Route exact path="/trainers"><Trainers /></Route>
              <Route exact path="/utilization"><Utilization /></Route>
              <Route exact path="/statistics"><Utilization /></Route>
              <Route exact path="/trainingreports"><TrainingReports /></Route>
              <Route exact path="/skillmap"><SkillMap /></Route>
              <Route exact path="/ct-admin"><CTAdmin /></Route> */}
              <Redirect to="/" />
            </Switch>
            :
            <Switch>
              <Route exact path="/"><Dashboard /></Route>
              <Redirect to="/" />
            </Switch>
          }
          </main>
          <MDBFooter style={{backgroundColor: '#2f3d5e'}}>
            <p className="footer-copyright mb-0 py-3 text-center">
              &copy; Copyright 2012 -  { new Date().getFullYear() }
              <a href="https://cloudthat.in"> CloudThat Technologies</a>
            </p>
          </MDBFooter>
        </div>
      </Router>
  );
}

export default App;
