import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBContainer, MDBRow,MDBModal } from 'mdbreact'
import React, { useEffect, useState } from 'react'
// import * as Msal from 'msal'
// import { msalConfig, config, loginRequest } from '../config'
// import { Redirect } from 'react-router-dom'
// import DATA from '../assets/data.json'
// import { connect } from 'react-redux'
import CountUp from 'react-countup'
import apiCall from '../apiCall'
import { Pie } from 'react-chartjs-2';

function Dashboard() {

    const [projectCount, setProjectCount] = useState()
    const [resourceCount, setResourceCount] = useState()
    const [individualCertificate, setIndividualCertificate] = useState()
    
    const [certificationCount, setCertificationCount] = useState()
    const [projecChartData, setProjectChartData] = useState()
    const [resourceChartData, setResourceChartData] = useState()
    
    const [certificationChartData, setCertificationChartData] = useState()

    const [ResourceCountforChart2, setResourceCountforChart2] = useState()
    const [IndividualCertificateCountChart2, setIndividualCertificateCountChart2] = useState()
    // const [CertificationCountforChart2, setCertificationCountforChart2] = useState()

    const [ AWSResource, setAWSResource] = useState(false)
    const [ AzureResource, setAzureResource] = useState(false)
    const [ GCPResource, setGCPResource] = useState(false)
    const [AllCertification, setAllCertification] = useState(false)

    const getProjectCount = async() => {
        var apiBody = {"getDisplayProjectsPercentage":"True"}
        var response = await apiCall(apiBody)
        console.log(response)

        const chartData = {
            labels: ['Internal Project', 'External Project'],
            datasets: [
                {
                    data: [response.Internalcount, response.Externalcount],
                    backgroundColor: ['#F7464A', '#46BFBD'],
                    hoverBackgroundColor: ['#FF5A5E', '#5AD3D1']
                }
            ]
        }
        setProjectCount(response)
        setProjectChartData(chartData)
    }

    const getResourceCount = async() => {
        var apiBody = {"getDisplaySkillsforPieChart":"True"}
        var response = await apiCall(apiBody)
        console.log(response["DevOps - Beginner"])
        console.log(response)
        
        
        var resources = {
            AIMLCount: response ["AI/ML - Beginner"] + response["AI/ML - Intermediate"] + response["AI/ML - Expert"],
            AWSCount : response["AWS - Beginner"] + response["AWS - Intermediate"] + response["AWS - Expert"],
            AzureCount: response["Azure - Beginner"] + response["Azure - Intermediate"] + response["Azure - Expert"],
            GCPCount : response["GCP - Beginner"] + response["GCP - Intermediate"] + response["GCP - Expert"],
            DataScienceCount: response["Data Science - Beginner"] + response["Data Science - Intermediate"] + response["Data Science - Expert"],
            // DevOpsCount : response["DevOps - Beginner"] + response["DevOps - Intermediate"] + response["DevOps - Expert"],
            DevOpsCount : response["DevOps - Beginner"] + response["DevOps - Intermediate"] + response["DevOps - Expert"],
            // DevelopmentCount : response["Python - Beginner"] + response["Python - Intermediate"] + response["Python - Expert"] + response["Web Development - Beginner"] + response["Web Development - Intermediate"] + response["Web Development - Expert"],
            DevelopmentCount : response["Python - Beginner" || "Web Development - Beginner"] + response["Python - Intermediate" || "Web Development - Intermediate"] + response["Python - Expert" || "Web Development - Expert"],
            Dynamics365Count: response["Dynamics 365 - Beginner"] + response["Dynamics 365 - Intermediate"] + response["Dynamics 365 - Expert"],
            IOTCount: response ["IoT - Beginner"] + response["IoT - Intermediate"] + response["IoT - Expert"],
            PowerPlatformCount: response ["Power Platform - Beginner"] + response["Power Platform - Intermediate"] + response["Power Platform - Expert"],
            LinuxCount: response ["Linux - Beginner"] + response["Linux - Intermediate"] + response["Linux - Expert"],
        }
        console.log(resources)
        setResourceCountforChart2({
            "AI/MLIntermediate": response["AI/ML - Intermediate"],
            "AI/MLBeginner": response["AI/ML - Beginner"],
            "AI/MLExpert": response["AI/ML - Expert"],

            "AWSIntermediate": response["AWS - Intermediate"],
            "AWSBeginner": response["AWS - Beginner"],
            "AWSExpert": response["AWS - Expert"],
            //azure
            "AzureIntermediate": response["Azure - Intermediate"],
            "AzureBeginner": response["Azure - Beginner"],
            "AzureExpert": response["Azure - Expert"],
            //GCP
            "GCPIntermediate": response["GCP - Intermediate"],
            "GCPBeginner": response["GCP - Beginner"],
            "GCPExpert": response["GCP - Expert"],
            
            "DataScienceIntermediate": response["Data Science - Intermediate"],
            "DataScienceBeginner": response["Data Science - Beginner"],
            "DataScienceExpert": response["Data Science - Expert"],
            //DevOps
            // "DevOpsIntermediate": response["DevOps - Intermediate"],
            // "DevOpsBeginner": response["DevOps - Beginner"],
            // "DevOpsExpert": response["DevOps - Expert"],
            //devops-3
            "AnsibleIntermediate": response["Ansible - Intermediate"],
            "AnsibleBeginner": response["Ansible - Beginner"],
            "AnsibleExpert": response["Ansible - Expert"],

            "DockerIntermediate": response["Docker - Intermediate"],
            "DockerBeginner": response["Docker - Beginner"],
            "DockerExpert": response["Docker - Expert"],

            "GitIntermediate": response["Git - Intermediate"],
            "GitBeginner": response["Git - Beginner"],
            "GitExpert": response["Git - Expert"],

            "IoTIntermediate": response["IoT - Intermediate"],
            "IoTBeginner": response["IoT - Beginner"],
            "IoTExpert": response["IoT - Expert"],

            "GrafanaIntermediate": response["Grafana - Intermediate"],
            "GrafanaBeginner": response["Grafana - Beginner"],
            "GrafanaExpert": response["Grafana - Expert"],

            "JenkinsIntermediate": response["Jenkins - Intermediate"],
            "JenkinsBeginner": response["Jenkins - Beginner"],
            "JenkinsExpert": response["Jenkins - Expert"],

            "KubernetesIntermediate": response["Kubernetes - Intermediate"],
            "KubernetesBeginner": response["Kubernetes - Beginner"],
            "KubernetesExpert": response["Kubernetes - Expert"],

            "PrometheusIntermediate": response["Prometheus - Intermediate"],
            "PrometheusBeginner": response["Prometheus - Beginner"],
            "PrometheusExpert": response["Prometheus - Expert"],

            "TerraformIntermediate": response["Terraform - Intermediate"],
            "TerraformBeginner": response["Terraform - Beginner"],
            "TerraformExpert": response["Terraform - Expert"],

            "DevOpsIntermediate": response["DevOps - Intermediate"],
            "DevOpsBeginner": response["DevOps - Beginner"],
            "DevOpsExpert": response["DevOps - Expert"],

            "Dynamics365Intermediate": response["Dynamics 365 - Intermediate"],
            "Dynamics365Beginner": response["Dynamics 365 - Beginner"],
            "Dynamics365Expert": response["Dynamics 365 - Expert"],

            "PythonIntermediate": response["Python - Intermediate"],
            "PythonBeginner": response["Python - Beginner"],
            "PythonExpert": response["Python - Expert"],

            "Web DevelopmentIntermediate": response["Web Development - Intermediate"],
            "Web DevelopmentBeginner": response["Web Development - Beginner"],
            "Web DevelopmentExpert": response["Web Development - Expert"],

            "LinuxIntermediate": response["Linux - Intermediate"],
            "LinuxBeginner": response["Linux - Beginner"],
            "LinuxExpert": response["Linux - Expert"],

            "PowerPlatformIntermediate": response["Power Platform - Intermediate"],
            "PowerPlatformBeginner": response["Power Platform - Beginner"],
            "PowerPlatformExpert": response["Power Platform - Expert"],
            //chart 2 devops
            AnsibleCount : response ["Ansible - Beginner"] + response["Ansible - Intermediate"] + response["Ansible - Expert"],
            DockerCount : response ["Docker - Beginner"] + response["Docker - Intermediate"] + response["Docker - Expert"],
            GitCount : response ["Git - Beginner"] + response["Git - Intermediate"] + response["Git - Expert"],
            GrafanaCount : response ["Grafana - Beginner"] + response["Grafana - Intermediate"] + response["Grafana - Expert"],
            JenkinsCount : response ["Jenkins - Beginner"] + response["Jenkins - Intermediate"] + response["Jenkins - Expert"],
            KubernetesCount : response ["Kubernetes - Beginner"] + response["Kubernetes - Intermediate"] + response["Kubernetes - Expert"],
            PrometheusCount : response ["Prometheus - Beginner"] + response["Prometheus - Intermediate"] + response["Prometheus - Expert"],
            TerraformCount : response ["Terraform - Beginner"] + response["Terraform - Intermediate"] + response["Terraform - Expert"],
            DevOpsCount : response ["DevOps - Beginner"] + response["DevOps - Intermediate"] + response["DevOps - Expert"],

            PythonCount: response["Python - Beginner"] + response["Python - Intermediate"] + response["Python - Expert"],
            WebdevelopmentCount : response["Web Development - Beginner"] + response["Web Development - Intermediate"] + response["Web Development - Expert"],
            

            
        })
        const chartData = {
            labels: ['AI/ML', 'AWS', 'Azure', 'Data Science','DevOps', 'Development','Dynamics 365','GCP','IoT', 'Linux','Power Platform'],
            datasets: [
            {
                data: [ resources.AIMLCount ,resources.AWSCount, resources.AzureCount, resources.DataScienceCount , resources.DevOpsCount,  resources.DevelopmentCount ,resources.Dynamics365Count,resources.GCPCount, resources.IOTCount,resources.LinuxCount, resources.PowerPlatformCount],
                backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C','#70b55b','#9c9119','#752d6c','#1ba8a4','#a86d1b','#d246f7','#a4440f', '#7b6b67'],
                hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870','#A8B3C5'],
               

            }
            ]
        }
        //resource c
        
        setResourceCount(resources)
        setResourceChartData(chartData)
    }

    //func
    const getIndividualCertificate = async() => {
        // var apiBody = {"getDisplayCertificateforPieChart": "True"}  
        var apiBody = {"getDisplayIndividualCertificatecount": "True"} 
        var response = await apiCall(apiBody)

        setIndividualCertificateCountChart2({
            "AI-900": response["AI900"],
            "AI-100": response["AI100"],
            "ANSIBLE-L2": response["ANSIBLEL2"],
            "AWS_DOP": response["AWS_DOP"],
            "AWS_DVA": response["AWS_DVA"],
            "AWS_SAA": response["AWS_SAA"],
            "AWS_SAP": response["AWS_SAP"],
            "AWS_SOA": response["AWS_SOA"],
            "AZ-103": response["AZ103"],
            "AZ-104": response["AZ104"],
            "AZ-203": response["AZ203"],
            "AZ-204": response["AZ204"],
            "AZ-220": response["AZ220"],
            "AZ-300": response["AZ300"],
            "AZ-301": response["AZ301"],
            "AZ-400": response["AZ400"],
            "AZ-500": response["AZ500"],
            "AZ-900": response["AZ900"],
            "CKA": response["CKA"],
            "CKAD": response["CKAD"],
            "DCAL3": response["DCAL3"],
            "KE": response["KE"],
            "DEVOPS": response["DEVOPS"],
            "DOCKER-L2": response["DOCKERL2"],
            "DP-100": response["DP100"],
            "DP-200": response["DP200"],
            "DP-201": response["DP201"],
            "DP-900": response["DP900"],
            "GCP-L3": response["GCPL3"],
            "MB-900": response["MB900"],
            "MS-700": response["MS700"],
            "MS-900": response["MS900"],
            
        })
        console.log(response)
        // const chartData = {
        //     labels: ['AWS ', 'Azure ', 'GCP ', 'DevOps '],
        //     datasets: [
        //     {
        //         // data: [response.AWSCertificatecount, response.AzureCertificatecount, response.GCPCertificatecount, response.DevopsCertificatecount],
        //         backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1'],
        //         hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#da92db', '#A8B3C5']
        //     }
        //     ]
        // }
        setIndividualCertificate(response)
        // setCertificationChartData(chartData)
        
    }
    //func end

    const getCertificationCount = async() => {
        var apiBody = {"getDisplayCertificateforPieChart": "True"}  
        // var apiBody = {"getDisplayIndividualCertificatecount": "True"} 
        var response = await apiCall(apiBody)
        console.log(response)
        const chartData = {
            labels: ['AWS ', 'Azure ' , 'DevOps ','GCP '],
            datasets: [
            {
                data: [response.AWSCertificatecount, response.AzureCertificatecount, response.DevopsCertificatecount, response.GCPCertificatecount],
                backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C', '#949FB1','#9c9119'],
                hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870','#A8B3C5']
            }
            ]
        }
        setCertificationCount(response)
        setCertificationChartData(chartData)
    }

    useEffect(async() => {
        await getProjectCount()
        await getResourceCount()
        getCertificationCount()
        getIndividualCertificate()
    }, [])

    const option = {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = parseFloat((currentValue/total*100).toFixed(2));
              return currentValue + ' (' + percentage + '%)';
            },
            title: function(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            }
          }
        },
        responsive: true,
      }

      //certification individual chart 2
      const CertificationChart2 = {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = parseFloat((currentValue/total*100).toFixed(2));
              return currentValue + ' (' + percentage + '%)';
            },
            title: function(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            }
          }
        },
        responsive: true,
        onClick: function(data, selectedIndex){
            console.log(selectedIndex)
            if(selectedIndex.length > 0) {
            if(selectedIndex?.[0]['_index'] === 0 && selectedIndex["0"]["_model"]["label"] === "AWS "){
                const chartData = {
                    labels: ['AWS-SAA', 'AWS-DVA', 'AWS-SOA','AWS-SAP','AWS-DOP'],
                    datasets: [
                    {
                        data: [ IndividualCertificateCountChart2['AWS_SAA'], IndividualCertificateCountChart2 ['AWS_DVA'], IndividualCertificateCountChart2['AWS_SOA'], IndividualCertificateCountChart2['AWS_SAP'], IndividualCertificateCountChart2['AWS_DOP']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C','#70b55b' ,'#9c9119'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAllCertification(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 1 && selectedIndex["0"]["_model"]["label"] === "Azure "){
                const chartData = {
                    labels:['AI-100  ', 'AI-900 ', 'AZ-103','AZ-104', 'AZ-203','AZ-204','AZ-220', 'AZ-300' , 'AZ-301', 'AZ-400','AZ-500','AZ-900','DP-100','DP-200','DP-201','DP-900','MB-900','MS-700','MS-900'],
                    datasets: [
                    {
                        data: [ IndividualCertificateCountChart2['AI-100'], IndividualCertificateCountChart2['AI-900'], IndividualCertificateCountChart2 ['AZ-103'], IndividualCertificateCountChart2 ['AZ-104'], IndividualCertificateCountChart2 ['AZ-203'],IndividualCertificateCountChart2 ['AZ-204'],
                        IndividualCertificateCountChart2 ['AZ-220'], IndividualCertificateCountChart2 ['AZ-300'], IndividualCertificateCountChart2 ['AZ-301'], IndividualCertificateCountChart2 ['AZ-400'],IndividualCertificateCountChart2 ['AZ-500'], IndividualCertificateCountChart2 ['AZ-900'], 
                        IndividualCertificateCountChart2 ['DP-100'], IndividualCertificateCountChart2 ['DP-200'], IndividualCertificateCountChart2 ['DP-201'],IndividualCertificateCountChart2 ['DP-900'],IndividualCertificateCountChart2 ['MB-900'],IndividualCertificateCountChart2 ['MS-700'],IndividualCertificateCountChart2 ['MS-900']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C' ,'#70b55b','#9c9119','#752d6c','#1ba8a4','#a86d1b','#d246f7' ,'#a4440f', '#7b6b67', '#F33FEE', '#23F3CC','#2F7265','#CBCBD0','#AE3A6D','#F7FA2A','#BFE920','#F1AF0F'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAllCertification(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
  
     
        
            else if(selectedIndex[0]?.['_index'] === 2 && selectedIndex["0"]["_model"]["label"] === "DevOps "){
                const chartData = {
                    labels: ['Ansible-L2','CKA','CKAD',' DCAL3','DevOps','Docker-L2 ','KE'],
                    datasets: [
                    {
                        data: [ IndividualCertificateCountChart2['ANSIBLE-L2'],IndividualCertificateCountChart2['CKA'],IndividualCertificateCountChart2['CKAD'],IndividualCertificateCountChart2['DCAL3'],IndividualCertificateCountChart2['DEVOPS'],IndividualCertificateCountChart2['DOCKER-L2'],IndividualCertificateCountChart2['KE']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C' ,'#70b55b','#9c9119','#752d6c','#1ba8a4' ],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1']
                    }
                    ]
                }
                setAllCertification(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 3 && selectedIndex["0"]["_model"]["label"] === "GCP "){
                const chartData = {
                    labels: ['GCP-L3'],
                    datasets: [
                    {
                        data: [IndividualCertificateCountChart2['GCP-L3'] ],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAllCertification(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
        
        }
            else
            {
                // setAzureResource(false)
                // setGCPResource(false)
                setAllCertification(false)

            }
            console.log("DATA", data)
            // console.log("abc", abc)
        }
      }
      //certification individual chart2 end

      const resourceOption = {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = parseFloat((currentValue/total*100).toFixed(2));
              return currentValue + ' (' + percentage + '%)';
            },
            title: function(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            }
          }
        },
        responsive: true,
        onClick: function(data, selectedIndex){
            console.log(selectedIndex)
            if(selectedIndex.length > 0) {
                if(selectedIndex?.[0]['_index'] === 0){
                    const chartData = {
                        labels: ['AI/ML-Intermediate', 'AI/ML-Beginner', 'AI/ML-Expert'],
                        datasets: [
                        {
                            data: [ ResourceCountforChart2['AI/MLIntermediate'], ResourceCountforChart2 ['AI/MLBeginner'], ResourceCountforChart2['AI/MLExpert']],
                            backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                            hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                        }
                        ]
                    }
                    setAWSResource(chartData)
                    // setAzureResource(false)
                    // setGCPResource(false)
                }

            if(selectedIndex?.[0]['_index'] === 1 && selectedIndex["0"]["_model"]["label"] === "AWS"){
                const chartData = {
                    labels: ['AWS-Intermediate', 'AWS-Beginner', 'AWS-Expert'],
                    
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['AWSIntermediate'], ResourceCountforChart2 ['AWSBeginner'], ResourceCountforChart2['AWSExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                console.log()
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 2){
                const chartData = {
                    labels: ['Azure-Intermediate', 'Azure-Beginner', 'Azure-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['AzureIntermediate'], ResourceCountforChart2 ['AzureBeginner'], ResourceCountforChart2['AzureExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
   
            else if(selectedIndex[0]?.['_index'] === 3){
                const chartData = {
                    labels: ['Data Science-Intermediate', 'Data Science-Beginner', 'Data Science-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['DataScienceIntermediate'], ResourceCountforChart2 ['DataScienceBeginner'], ResourceCountforChart2['DataScienceExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            //DevOps
            else if(selectedIndex[0]?.['_index'] === 4){
                const chartData = {
                    // labels: ['DevOps-Intermediate', 'DevOps-Beginner', 'DevOps-Expert'],
                    labels:['Ansible', 'Docker','Git','Grafana','Jenkins','Kubernetes','Prometheus','Terraform'],
                    datasets: [
                    {
                        // data: [ ResourceCountforChart2['DevOpsIntermediate'], ResourceCountforChart2 ['DevOpsBeginner'], ResourceCountforChart2 ['DevOpsExpert']],
                        data: [ ResourceCountforChart2.AnsibleCount, ResourceCountforChart2.DockerCount, ResourceCountforChart2.GitCount,ResourceCountforChart2.GrafanaCount,ResourceCountforChart2.JenkinsCount,ResourceCountforChart2.KubernetesCount, ResourceCountforChart2.PrometheusCount, ResourceCountforChart2.TerraformCount],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C','#70b55b','#9c9119','#752d6c','#1ba8a4','#a86d1b','#d246f7'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
   
         
            else if(selectedIndex[0]?.['_index'] === 5){
                const chartData = {
                    labels: ['Python', 'Web Development'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2.PythonCount, ResourceCountforChart2.WebdevelopmentCount],
                        backgroundColor: ['#F7464A', '#46BFBD', ],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 6){
                const chartData = {
                    labels: ['Dynamics365-Intermediate', 'Dynamics365-Beginner', 'Dynamics365-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['Dynamics365Intermediate'], ResourceCountforChart2 ['Dynamics365Beginner'], ResourceCountforChart2['Dynamics365Expert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            
            else if(selectedIndex[0]?.['_index'] === 7){
                const chartData = {
                    labels: ['GCP-Intermediate', 'GCP-Beginner', 'GCP-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['GCPIntermediate'], ResourceCountforChart2 ['GCPBeginner'], ResourceCountforChart2['GCPExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 8){
                const chartData = {
                    labels: ['IoT-Intermediate', 'IoT-Beginner', 'IoT-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['IoTIntermediate'], ResourceCountforChart2 ['IoTBeginner'], ResourceCountforChart2['IoTExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 9){
                const chartData = {
                    labels: ['Linux-Intermediate', 'Linux-Beginner', 'Linux-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['LinuxIntermediate'], ResourceCountforChart2 ['LinuxBeginner'], ResourceCountforChart2['LinuxExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 10){
                const chartData = {
                    labels: ['Power Platform-Intermediate', 'Power Platform-Beginner', 'Power Platform-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['PowerPlatformIntermediate'], ResourceCountforChart2 ['PowerPlatformBeginner'], ResourceCountforChart2['PowerPlatformExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
        
        
        }
            else
            {
                setAzureResource(false)
                setGCPResource(false)
                setAWSResource(false)

            }
            console.log("DATA", data)
            // console.log("abc", abc)
        }
      }

      //code for 3rd chart

      const resourceOption3 = {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              var dataset = data.datasets[tooltipItem.datasetIndex];
              var meta = dataset._meta[Object.keys(dataset._meta)[0]];
              var total = meta.total;
              var currentValue = dataset.data[tooltipItem.index];
              var percentage = parseFloat((currentValue/total*100).toFixed(2));
              return currentValue + ' (' + percentage + '%)';
            },
            title: function(tooltipItem, data) {
              return data.labels[tooltipItem[0].index];
            }
          }
        },
        responsive: true,
        onClick: function(data, selectedIndex){
            console.log(selectedIndex)
            if(selectedIndex.length > 0) {
            if(selectedIndex?.[0]['_index'] === 0 && selectedIndex["0"]["_model"]["label"] === "Ansible"){
                const chartData = {
                    labels: ['Ansible-Intermediate', 'Ansible-Beginner', 'Ansible-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['AnsibleIntermediate'], ResourceCountforChart2 ['AnsibleBeginner'], ResourceCountforChart2['AnsibleExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 1 && selectedIndex["0"]["_model"]["label"] === "Docker"){
                const chartData = {
                    labels: ['Docker-Intermediate', 'Docker-Beginner', 'Docker-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['DockerIntermediate'], ResourceCountforChart2 ['DockerBeginner'], ResourceCountforChart2['DockerExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 0 && selectedIndex["0"]["_model"]["label"] === "Python"){
                const chartData = {
                    labels: ['Python-Intermediate', 'Python-Beginner', 'Python-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['PythonIntermediate'], ResourceCountforChart2 ['PythonBeginner'], ResourceCountforChart2['PythonExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 1 && selectedIndex["0"]["_model"]["label"] === "Web Development"){
                const chartData = {
                    labels: ['Web Development-Intermediate', 'Web Development-Beginner', 'Web Development-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['Web DevelopmentIntermediate'], ResourceCountforChart2 ['Web DevelopmentBeginner'], ResourceCountforChart2['Web DevelopmentExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 2 && selectedIndex["0"]["_model"]["label"] === "Git"){
                const chartData = {
                    labels: ['Git-Intermediate', 'Git-Beginner', 'Git-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['GitIntermediate'], ResourceCountforChart2 ['GitBeginner'], ResourceCountforChart2['GitExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            //DevOps
            else if(selectedIndex[0]?.['_index'] === 3){
                const chartData = {
                    labels: ['Grafana-Intermediate', 'Grafana-Beginner', 'Grafana-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['GrafanaIntermediate'], ResourceCountforChart2 ['GrafanaBeginner'], ResourceCountforChart2['GrafanaExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }
            else if(selectedIndex[0]?.['_index'] === 4){
                const chartData = {
                    labels: ['Jenkins-Intermediate', 'Jenkins-Beginner', 'Jenkins-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['JenkinsIntermediate'], ResourceCountforChart2 ['JenkinsBeginner'], ResourceCountforChart2['JenkinsExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }

            else if(selectedIndex[0]?.['_index'] === 5){
                const chartData = {
                    labels: ['Kubernetes-Intermediate', 'Kubernetes-Beginner', 'Kubernetes-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['KubernetesIntermediate'], ResourceCountforChart2 ['KubernetesBeginner'], ResourceCountforChart2['KubernetesExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }

            else if(selectedIndex[0]?.['_index'] === 6){
                const chartData = {
                    labels: ['Prometheus-Intermediate', 'Prometheus-Beginner', 'Prometheus-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['PrometheusIntermediate'], ResourceCountforChart2 ['PrometheusBeginner'], ResourceCountforChart2['PrometheusExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }

            else if(selectedIndex[0]?.['_index'] === 7){
                const chartData = {
                    labels: ['Terraform-Intermediate', 'Terraform-Beginner', 'Terraform-Expert'],
                    datasets: [
                    {
                        data: [ ResourceCountforChart2['TerraformIntermediate'], ResourceCountforChart2 ['TerraformBeginner'], ResourceCountforChart2['TerraformExpert']],
                        backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
                        hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
                    }
                    ]
                }
                setAWSResource(chartData)
                // setAzureResource(false)
                // setGCPResource(false)
                // setAWSResource(false)
            }

            // else if(selectedIndex[0]?.['_index'] === 8){
            //     const chartData = {
            //         labels: ['DevOps-Intermediate', 'DevOps-Beginner', 'DevOps-Expert'],
            //         datasets: [
            //         {
            //             data: [ ResourceCountforChart2['DevOpsIntermediate'], ResourceCountforChart2 ['DevOpsBeginner'], ResourceCountforChart2['DevOpsExpert']],
            //             backgroundColor: ['#F7464A', '#46BFBD', '#FDB45C'],
            //             hoverBackgroundColor: ['#FF5A5E', '#5AD3D1', '#FFC870']
            //         }
            //         ]
            //     }
            //     setAWSResource(chartData)
            //     // setAzureResource(false)
            //     // setGCPResource(false)
            //     // setAWSResource(false)
            // }





        }
            else
            {
                setAzureResource(false)
                setGCPResource(false)
                setAWSResource(false)

            }
            console.log("DATA", data)
           
        }
      }
      //code end 3rd chart

    return (
        <main style={{marginTop: '2rem'}} className="mainClass">
            <MDBContainer>
                <MDBRow className="mb-4">
                    {projectCount &&
                        <MDBCol xl="4" md="6" className="mb-3">
                            <MDBCard className="classic-admin-card">
                                <MDBCardHeader style={{backgroundColor: 'rgb(47, 61, 94)'}}>
                                    <div className="float-right">
                                        <h4 className="white-text"><CountUp end={projectCount?.Internalcount + projectCount?.Externalcount} duration={2} /></h4>
                                    </div>
                                    <h4 className="white-text">Projects</h4>
                                </MDBCardHeader>
                                <MDBCardBody>
                                    <h5>
                                        Internal
                                        <div className="float-right">
                                            <CountUp end={projectCount?.Internalcount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        External
                                        <div className="float-right">
                                            <CountUp end={projectCount?.Externalcount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>&nbsp;</h5>
                                    <h5>&nbsp;</h5>
                                    <Pie data={projecChartData} height={200} width={200} options={option}  />
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    }
                    {resourceCount &&
                        <MDBCol xl="4" md="6" className="mb-3">
                            <MDBCard className="classic-admin-card">
                                <MDBCardHeader style={{backgroundColor: 'rgb(47, 61, 94)'}}>
                                    {/* <div className="float-right">
                                        <h4 className="white-text"><CountUp end={resourceCount?.AIMLCount + resourceCount?.AWSCount + resourceCount?.AzureCount  + resourceCount?.DataScienceCount +  resourceCount?.DevOpsCount 
                                         + resourceCount?.DevelopmentCount + resourceCount?.Dynamics365Count + resourceCount?.GCPCount + resourceCount?.IOTCount +  resourceCount?.LinuxCount +  resourceCount?.PowerPlatformCount} duration={2} /></h4>
                                    </div> */}
                                    <h4 className="white-text">Skills</h4>
                                </MDBCardHeader>
                                <MDBCardBody>
                                <h5>
                                        AI/ML
                                        &nbsp;
                                        <div className="float-right" >
                                            <CountUp end={resourceCount?.AIMLCount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        AWS 
                                        &nbsp;
                                        <div className="float-right" >
                                            <CountUp end={resourceCount?.AWSCount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        Azure
                                        &nbsp;
                                        <div className="float-right">
                                            <CountUp end={resourceCount?.AzureCount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        Data Science
                                        <div className="float-right">
                                            <CountUp end={resourceCount?.DataScienceCount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        DevOps
                                        <div className="float-right">
                                            <CountUp end={resourceCount?.DevOpsCount} duration={2} />
                                        </div>
                                    </h5>
                                      
                                    <h5>
                                        Development
                                        <div className="float-right">
                                            <CountUp end={resourceCount?.DevelopmentCount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        Dynamics 365
                                        <div className="float-right">
                                            <CountUp end={resourceCount?.Dynamics365Count} duration={2} />
                                        </div>
                                    </h5>
                                   
                                    <h5>
                                        GCP
                                        <div className="float-right">
                                            <CountUp end={resourceCount?.GCPCount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        IoT
                                        <div className="float-right">
                                            <CountUp end={resourceCount?.IOTCount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        Linux
                                        <div className="float-right">
                                            <CountUp end={resourceCount?.LinuxCount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        Power Platform
                                        <div className="float-right">
                                            <CountUp end={resourceCount?.PowerPlatformCount} duration={2} />
                                        </div>
                                    </h5>
                                 
                                
                                    {/* <div className="col-md-12" id="resourceChart" style={{color:'black !important'}}>
                                        MAYUR PATEL
                                    </div> */}
                                   
                                   {/* <div style={{height: '240px'}}> */}
                                    <Pie data={resourceChartData} height={250} width={200} options={resourceOption} />
                                    {/* </div> */}
                                    {AWSResource &&
                                            <Pie data={AWSResource} height={200} width={200} options={resourceOption3} />
                                        
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    }
                    {certificationCount &&
                        <MDBCol xl="4" md="6" className="mb-3">
                            <MDBCard className="classic-admin-card">
                                <MDBCardHeader style={{backgroundColor: 'rgb(47, 61, 94)'}}>
                                    <div className="float-right">
                                        <h4 className="white-text"><CountUp end={certificationCount?.AWSCertificatecount + certificationCount?.AzureCertificatecount +  certificationCount?.DevopsCertificatecount  + certificationCount?.GCPCertificatecount } duration={2} /></h4>
                                    </div>
                                    <h4 className="white-text">Certificates</h4>
                                </MDBCardHeader>
                                <MDBCardBody>
                                    <h5>
                                        AWS
                                        <div className="float-right">
                                            <CountUp end={certificationCount?.AWSCertificatecount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        Azure
                                        <div className="float-right">
                                            <CountUp end={certificationCount?.AzureCertificatecount} duration={2} />
                                        </div>
                                    </h5>
                                  
                                    <h5>
                                        DevOps
                                        <div className="float-right">
                                            <CountUp end={certificationCount?.DevopsCertificatecount} duration={2} />
                                        </div>
                                    </h5>
                                    <h5>
                                        GCP
                                        <div className="float-right">
                                            <CountUp end={certificationCount?.GCPCertificatecount} duration={2} />
                                        </div>
                                    </h5>
                                    <Pie data={certificationChartData} height={200} width={200} options={CertificationChart2} />
                                    {AllCertification &&
                                    <Pie data={AllCertification} height={230} width={200} options={CertificationChart2} />
                                    }
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    }
                </MDBRow>
                
            </MDBContainer>
        </main>
    )
}
export default Dashboard