var uri = "https://consultants.cloudthat.com/"
var apiURL = "https://327qghfexc.execute-api.ap-south-1.amazonaws.com/prod"
if(window.location.href.includes('localhost')){
   uri = window.location.origin
}
export const msalConfig = {
    auth: {
        clientId: "0b923d0c-ea66-4fe2-8b30-fa43b8049e22",
        authority: "https://login.microsoftonline.com/815db2f7-1e3a-438f-8bdd-e55de825adee/",
        redirectUri: uri,
        postLogoutRedirectUri: uri,
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new
    },
};
  
export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"],
};

export const config = {
    apiURL : apiURL,
}
