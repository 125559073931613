import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Inject, Page, Sort, Toolbar } from '@syncfusion/ej2-react-grids'
import React, { useEffect, useState } from 'react'
import apiCall from '../apiCall'
import { MDBBtn, MDBCol, MDBIcon, MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow } from 'mdbreact'
import DATA from '../assets/data.json'
import {  DropDownListComponent, CheckBoxSelection,MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns'
import { ToastComponent } from '@syncfusion/ej2-react-notifications'
import { MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import * as MSAL from 'msal'
import { msalConfig, config, loginRequest  } from '../config'
import { useStateValue } from '../StateProvider'
const myMSALObj = new MSAL.UserAgentApplication(msalConfig)
// const myMSALObj = new Msal.UserAgentApplication(msalConfig)

const userName = new MSAL.UserAgentApplication(msalConfig).getAccount()?.userName

const Projects = () => {
    // state = {
    //     data: allData.slice()
    // };
    const [tableData, setTableData] = useState()
    const [addProjectModal, setAddProjectModal] = useState(false)
    const [Toast, setToast] = useState()
    
    

    //con-update-st
    const[{user}] = useStateValue()
    const[editProjectModal, setEditProjectModal] = useState(false)
    // const[updateEmailModal, setUpdateEmailModal] = useState(false)
    const[confirmModal, setConfirmModal] = useState(false)
    const[editProjectDetails, setEditProjectDetails] = useState()
    // const[updateEmailDetails, setUpdateEmailDetails] = useState()
    const[disableProjectDetails, setDisableProjectDetails] = useState()
    const[editSkills, setEditSkills] = useState()
    const[editCertificates, setEditCertificates] = useState()  //line added to get default certificate


    const generateActions = (projectDetails) => {
        if( Object.keys(projectDetails).includes('disabled')){
            return <div>Project Disabled Contact Technical Team to restore.</div>
        } else {
            return (
                <div>
                    <a title="Update Project" data-toggle="tooltip">
                        <MDBIcon icon="edit" className="black-text pr-3" onClick={ () => {editProject(projectDetails)}} />
                    </a>
                    <a title="Disabled Project" data-toggle="tooltip">
                        <MDBIcon icon="times" className="red-text pr-3" onClick={ () => {disabledProject(projectDetails)}} />
                    </a>
                </div>
                );
        }

    }
// function Projects() {

//     const [tableData, setTableData] = useState()
//     const [addProjectModal, setAddProjectModal] = useState(false)
//     const [Toast, setToast] = useState()

    const getProjectsData = async() => {
        var apiBody = {'getCTProjects':'True'}
        var response = await apiCall(apiBody)
        var projectList = []
        console.log(projectList)
        console.log(response)
        
        for (var i=0; i < response.length; i++) {
        console.log(projectList)
        console.log(response)
            projectList.push({
                name: response[i].employeename.S,
                email: response[i].employeeemail.S,
                role: response[i].role.S,
                projectType: response[i].projectType.S,
                projectStatus: response[i].projectStatus.S,
                projectName: response[i].projectName.S,
                client: response[i].client.S,
                action: generateActions(response[i])
            })
        }
        setTableData(projectList)
    }

    // const addProject = () => {

    // }
    var employeeRoleOptions=[], employeeRole = DATA.EmployeeRole
    for(var i=0; i<employeeRole.length; i++)
        employeeRoleOptions.push({
            name: employeeRole[i],
            id: employeeRole[i]
        })
    var projectTypeOptions=[], projectTypes = DATA.ProjectTypes
    for(var i=0; i<projectTypes.length; i++)
        projectTypeOptions.push({
            name: projectTypes[i],
            id: projectTypes[i]
        })
    var projectStatusOptions=[], projectStatus = DATA.ProjectStatus
    for(var i=0; i<projectStatus.length; i++)
        projectStatusOptions.push({
            name: projectStatus[i],
            id: projectStatus[i]
        })
    
    var projectPriorityOptions=[], projectPriorities = DATA.ProjectPriorities
    for(var i=0; i<projectPriorities.length; i++)
        projectPriorityOptions.push({
            name: projectPriorities[i],
            id: projectPriorities[i]
        })
    

    useEffect(() => {
        getProjectsData()
    }, [])

    // const filterChange = (event) => {
    //     // this.setState({
    //     //     data: this.filterData(event.filter)
    //     // });
    //     console.log(event.filter);
    // }
    
    const editProject = (projectDetail) => {
        var unwantedItems = ["name","email","type","disabled","lastAction", "updateProject", "createdAt", "modifiedAt", "modifiedBy", "createdDateTime"]
        console.log(projectDetail)
        var skills = Object.keys(projectDetail).filter((obj) => {
                return unwantedItems.indexOf(obj) == -1; 
            }
            
        );
        //start-certificates code
        // var certificates = Object.keys(projectDetail).filter((obj) => {
        //             return unwantedItems.indexOf(obj) == -1; 
        //         }
        // );      
        //end certification code
        // console.log(parseFloat(projectDetail["experience"]["N"]))
        // if(projectDetail["experience"]["N"] !== 0 && parseFloat(projectDetail["experience"]["N"]) % 2 !== 0)
        //     projectDetail["experience"]["N"] = parseFloat (projectDetail["experience"]["N"]) + ".0"    
        // else 
        //     projectDetail["experience"]["N"] = "0.0"
        setEditProjectModal(true)
        setEditSkills(skills)
        // setEditCertificates(certificates) //line added to get defaULT CERTIFICATES
        setEditProjectDetails(projectDetail)
        
    }
    const disabledProject = (projectDetail) => {
        setConfirmModal(true)
        setDisableProjectDetails(projectDetail)    
    }
    
    const disableProject = async (e) => {
        e.preventDefault()
        // var empId = document.getElementById('empId').value
        var apiBody = {
            "method": 'disableProject',
            "disable": "True",
            "employeeid": disableProjectDetails.employeeid.N,
            // "method": 'disableProject',
            "modifiedBy": userName
        }
        await fetch(config.apiURL, { method: "POST", body: JSON.stringify(apiBody)})
            .then(response => response.json())
            .then(response => {
                for(var i=0;i<response.length;i++){
                    if(response[i].ResponseMetadata?.HTTPStatusCode !== 200){
                        alert('Something went wrong please contact Administrator.')
                        return
                    }
                }
                alert('Project Disabled Successfully')
                window.location.reload()
            })
            .catch(error => alert(error.message))
    }

    const toolbarOptions = ['Search', 'Print'];

    const addProject = async(e) => {
        e.preventDefault()
        e.target.className += " was-validated";
        var empName = document.getElementById('empName').value
        var empEmail = document.getElementById('empEmail').value
        var empRole = document.getElementById('empRole').value
        var projType = document.getElementById('projType').value
        var projStatus = document.getElementById('projStatus').value
        var projPriorities = document.getElementById('projPriorities').value
        var clientName = document.getElementById('clientName').value
        var projName = document.getElementById('projName').value
        var projectsDate = document.getElementById('projectsDate').value
        var projecteDate = document.getElementById('projecteDate').value
        if(empName !== '' && empEmail  !== '' && empRole !== '' && projType !== ''  && projStatus !== '' && projPriorities !== '' && clientName !== '' && projName !== '' && projectsDate !== '' && projecteDate !== ''){
            if(empEmail.split('@')[1].includes('cloudthat.com') || empEmail.split('@')[1].includes('cloudthat.in')){
                document.getElementsByName('btnAddProject')[0].disabled = true
                var apiBody = {
                    "addCTProject": "True",
                    "employeeemail": empEmail,
                    "employeename": empName,
                    "role": empRole,
                    "projectType" : projType,
                    "projectStatus" : projStatus,
                    "projectPriority" : projPriorities,
                    "client" : clientName,
                    "projectName" : projName,
                    "projectstartdate" : projectsDate,
                    "projectenddate" : projecteDate,
                    "submittedBy": myMSALObj.getAccount().userName
                }
                console.log("API BODY", apiBody)
                var response = await apiCall(apiBody)
                console.log(response)
                if(response.ResponseMetadata.HTTPStatusCode === 200){
                    getProjectsData()
                    setAddProjectModal(false)
                    toastShow('Success', "Project Added Successfully", 'success')
                }
                else 
                    toastShow('Error!', 'Something went wrong, Please contact Administrator.', 'danger')
            } else {
                toastShow('Error!', 'Please Enter valid Email Address', 'danger')
            }
        } else {
            // alert("Please fill the all details")
            toastShow('Error!!',"Please fill all the details", 'danger')
        }
    }

    //update project start
    const updateProject = async(e) => {
        e.preventDefault()
        e.target.className += " was-validated";
        var projectId = document.getElementById('projectId').value
        var empName = document.getElementById('empName').value
        var empEmail = document.getElementById('empEmail').value
        var empRole = document.getElementById('empRole').value
        var projType = document.getElementById('projType').value
        var projStatus = document.getElementById('projStatus').value
        var projPriorities = document.getElementById('projPriorities').value
        var clientName = document.getElementById('clientName').value
        var projName = document.getElementById('projName').value
        var projectsDate = document.getElementById('projectsDate').value
        var projecteDate = document.getElementById('projecteDate').value
        // var exp = document.getElementById('empExpYears').value+"."+document.getElementById("empExpMonths").value
        if(empName !== '' && empEmail  !== '' && empRole !== '' && projType !== ''  && projStatus !== '' && projPriorities !== '' && clientName !== '' && projName !== '' && projectsDate !== '' && projecteDate !== ''){
            // if(empEmail.split('@')[1].includes('cloudthat.com') || empEmail.split('@')[1].includes('cloudthat.in')){

                if(empEmail.split('@')[1].includes('cloudthat.com') || empEmail.split('@')[1].includes('cloudthat.in')){
                    document.getElementsByName('updateProject')[0].disabled = true
                
                var apiBody = {
                    "updateCTProject": "True",
                    "projectId": projectId,
                    "employeeemail": empEmail,
                    "employeename": empName,
                    "role": empRole,
                    "projectType" : projType,
                    "projectStatus" : projStatus,
                    "projectPriority" : projPriorities,
                    "client" : clientName,
                    "projectName" : projName,
                    "projectstartdate" : projectsDate,
                    "projectenddate" : projecteDate,
                    "submittedBy": myMSALObj.getAccount().userName

                }
               
                var response = await apiCall(apiBody)
                console.log(response)
                if(response.ResponseMetadata.HTTPStatusCode === 200){
                    getProjectsData()
                    setEditProjectModal(false)
                    toastShow('Success', "Profile Updated Successfully", 'success')
                    // window.location.reload()  
                }
                else 
                    toastShow('Error!', 'Something went wrong, Please contact Administrator.', 'danger')
            //  else {
            //     toastShow('Error!', 'Please Enter valid Email Address', 'danger')
            // }
            }
        } else {
            // alert("Please fill the all details")
            toastShow('Error!!',"Please fill all the details", 'danger')
        }
    }
    //update project end

    const toastShow = (title, text, style) => {
        setTimeout(() => {
            Toast.target = '#mainDiv'
            Toast.show( {title: `${title}`, content: text, cssClass: `e-toast-${style}`});
        }, 200);
    }

    const projectChange = (args) =>{
        if (args.value === "External")
        {document.getElementById('clientName').disabled = false
            // document.getElementById('projPriorities').disabled = true
            document.getElementById('projPriorities').value = 'High'
            document.getElementById('clientName').value =""
    }
        // else
        //     document.getElementById('projPriorities').value = ''
        if(args.value === "Internal" )
        {document.getElementById('clientName').disabled = true
            document.getElementById('clientName').value = "CloudThat"
            document.getElementById('projPriorities').value =""
        }
        // else
        //     document.getElementById('clientName').value =""

        
        console.log(args.value)
      }



    return (
        <div style={{ marginTop: '3%', backgroundColor:""}} id="mainDiv">
        {DATA.Admins.includes(userName) &&
            <MDBContainer>
                <MDBRow end>
                    <MDBBtn type="button" id="btnColor" className="pull-right" style={{zIndex:3}} onClick={() => setAddProjectModal(true)}> Add Project</MDBBtn>
                </MDBRow>
            </MDBContainer>
        }
            <div style={{textAlign:"center"}} className=" col-md-12 col-sm-12 col-xs-12 ">
                <GridComponent
                    dataSource={tableData}
                    toolbar={toolbarOptions}
                    allowPaging={true}
                    pageSettings={{pageSize: 50,pageSizes: [50,100,150,200], pageCount: 5}}
                    allowTextWrap={true}
                    allowFiltering={true}
                    showColumnChooser={true}
                    allowExcelExport={true}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowPdfExport={true}
                    allowResizing={true}
                    searchSettings={
                        {fields: ['empId', 'name', 'designation', 'l1Manager', 'l2Manager'],
                        ignoreCase: true,
                        operator: 'contains'}
                    }
                    sortSettings={{columns: [{ field: 'name', direction: 'Ascending' }]}}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='name' headerText='Name' textAlign='Left'/>
                        <ColumnDirective field='email' headerText='Email' textAlign='Left'/>
                        <ColumnDirective field='role' headerText='Role' textAlign='Left'/>
                        <ColumnDirective field='projectName' headerText='Project Name' textAlign='Left'/>
                        <ColumnDirective field='projectType' headerText='Project Type' textAlign='Left'/>
                        <ColumnDirective field='projectStatus' headerText='Project Status' textAlign='Left'/>
                        <ColumnDirective field='client' headerText='Client Name' textAlign='Left'/>  
                        {DATA.Admins.includes(userName) &&
                        <ColumnDirective field='action' headerText='Action' headerTextAlign={'Center'} template={(props) => {return props.action}}/>
                        }
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Page, Sort, Filter]}/>
                </GridComponent>
            </div>
            
            <MDBModal isOpen={addProjectModal} toggle={() => setAddProjectModal(false)} size="lg">
                <form className="needs-validation" onSubmit={addProject} noValidate id="addProjectForm"> 
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" onClick={() =>  setAddProjectModal(false)}>×</button>
                {/* noValidate */}
                
                    <MDBModalHeader>Add Project Profile</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="empName" className="mb-0">Employee Name</label>
                                    <input className="form-control" type="text" required id="empName" placeholder="Enter Employee Name" />
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="empEmail" className="mb-0">Employee Email</label>
                                    <input className="form-control" type="email" required id="empEmail" placeholder="Enter Employee Email" />
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol>
                              <div className="form-group">
                                 <label htmlFor="empRole" className="mb-0">Role </label>
                                 <DropDownListComponent
                                        id="empRole"
                                        dataSource={employeeRoleOptions}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Select Project Role"
                                        sortOrder="Ascending"
                                 />

                              </div>
                            </MDBCol>

                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projType" className="mb-0">Project Type</label>
                                    <DropDownListComponent
                                        id="projType"
                                        dataSource={projectTypeOptions}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Select Project Type"
                                        sortOrder="Ascending"
                                        // onChange={handleChange}
                                        // onFilterChange={this.handleChange}
                                        // disabled= {true}
                                        // select={projectChange}
                                        change={projectChange}
                                    />
                                </div>
                            </MDBCol>

                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projStatus" className="mb-0">Project Status </label>
                                    <DropDownListComponent
                                        id="projStatus"
                                        dataSource={projectStatusOptions}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Select Project Status"
                                        sortOrder="Ascending"
                                    />
                                </div>
                            </MDBCol>

                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projPriorities" className="mb-0">Project Priority</label>
                                    <DropDownListComponent
                                        id="projPriorities"
                                        dataSource={projectPriorityOptions}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Slect Project Priority"
                                        sortOrder="Ascending"
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="clientName" className="mb-0">Client</label>
                                    <MaskedTextBoxComponent className="form-control" type="text" required id="clientName" placeholder="Enter Client Name" />
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projName" className="mb-0">Project Name</label>
                                    <MaskedTextBoxComponent className="form-control" type="text" required id="projName" placeholder="Enter Project Name" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projectsDate" className="mb-0">Start Date</label>
                                    <DatePickerComponent id="projectsDate" allowEdit={false} openOnFocus={true} format="dd-MM-yyyy" />
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projecteDate" className="mb-0">End Date</label>
                                    <DatePickerComponent id="projecteDate" allowEdit={false} openOnFocus={true} format="dd-MM-yyyy" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                    <MDBBtn type="submit" id="btnColor" name="btnAddProject" className="pull-right" style={{zIndex:3}}> Add Project</MDBBtn></MDBModalFooter>
                </form>
            </MDBModal>
            <ToastComponent
                ref={toast => setToast(toast)}
                position={ {X: 'Right', Y: 'Top' }}
            />

            {/* project update start */}
            <MDBModal isOpen={editProjectModal} toggle={() => setEditProjectModal(false)} size="lg" centered>
                <form className="needs-validation" onSubmit={updateProject} noValidate id="addProjectForm"> 
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" onClick={() => setEditProjectModal(false)}>×</button>
                {/* noValidate */}
                    <MDBModalHeader id="modalHeader">Update Project</MDBModalHeader>
                    <MDBModalBody>
                    <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projectId" className="mb-0">Project Id</label>
                                    <input className="form-control" type="text" required id="projectId" placeholder="Enter Project ID" defaultValue={editProjectDetails?.projectId.S} disabled required/>
                                </div>
                            </MDBCol></MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="empName" className="mb-0">Employee Name</label>
                                    <input className="form-control" type="text" required id="empName" placeholder="Enter Employee Name" defaultValue={editProjectDetails?.employeename.S}/>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="empEmail" className="mb-0">Employee Email</label>
                                    <input className="form-control" type="email" required id="empEmail" placeholder="Enter Employee Email" defaultValue={editProjectDetails?.employeeemail.S} />
                                </div>
                            </MDBCol>
                        </MDBRow>

                        <MDBRow>
                            <MDBCol>
                              <div className="form-group">
                                 <label htmlFor="empRole" className="mb-0">Role </label>
                                 <DropDownListComponent
                                        id="empRole"
                                        dataSource={employeeRoleOptions}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Select Project Role"
                                        sortOrder="Ascending"
                                        value={editProjectDetails?.role.S}
                                 />

                              </div>
                            </MDBCol>

                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projType" className="mb-0">Project Type</label>
                                    <DropDownListComponent
                                        id="projType"
                                        dataSource={projectTypeOptions}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Select Project Type"
                                        sortOrder="Ascending"
                                        value={editProjectDetails?.projectType.S}
                                        change={projectChange}
                                    
                                    />
                                </div>
                            </MDBCol>

                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projStatus" className="mb-0">Project Status </label>
                                    <DropDownListComponent
                                        id="projStatus"
                                        dataSource={projectStatusOptions}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Select Project Status"
                                        sortOrder="Ascending"
                                        value={editProjectDetails?.projectStatus.S}
                                    />
                                </div>
                            </MDBCol>

                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projPriorities" className="mb-0">Project Priority</label>
                                    <DropDownListComponent
                                        id="projPriorities"
                                        dataSource={projectPriorityOptions}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Slect Project Priority"
                                        sortOrder="Ascending"
                                        value={editProjectDetails?.projectPriority.S}
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="clientName" className="mb-0">Client</label>
                                    <MaskedTextBoxComponent className="form-control" type="text" required id="clientName" placeholder="Enter Client Name" value={editProjectDetails?.client.S} />
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projName" className="mb-0">Project Name</label>
                                    <MaskedTextBoxComponent className="form-control" type="text" required id="projName" placeholder="Enter Project Name" value={editProjectDetails?.projectName.S} />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projectsDate" className="mb-0">Start Date</label>
                                    <DatePickerComponent id="projectsDate" allowEdit={false} openOnFocus={true} format="dd-MM-yyyy" value={editProjectDetails?.projectstartdate.S}/>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="projecteDate" className="mb-0">End Date</label>
                                    <DatePickerComponent id="projecteDate" allowEdit={false} openOnFocus={true} format="dd-MM-yyyy" value={editProjectDetails?.projectenddate.S} />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                    <MDBBtn type="submit" id="btnColor" name="updateProject" className="pull-right" style={{zIndex:3}}> Update Project</MDBBtn></MDBModalFooter>
                </form>
            </MDBModal>
            {/* project update end */}

        </div>
    )
}

export default Projects