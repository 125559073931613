import React, { useEffect, useState } from 'react'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Sort, Toolbar } from '@syncfusion/ej2-react-grids'
import apiCall from '../apiCall'
import { MDBBtn, MDBCol,MDBIcon, MDBContainer, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow } from 'mdbreact'
import DATA from '../assets/data.json'
import { CheckBoxSelection, DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns'
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import {  MaskedTextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { ToastComponent } from '@syncfusion/ej2-react-notifications'
import * as MSAL from 'msal'
import { msalConfig, config, loginRequest } from '../config'
import { useStateValue } from '../StateProvider'
const myMSALObj = new MSAL.UserAgentApplication(msalConfig)

//below line added con-up
const userName = new MSAL.UserAgentApplication(msalConfig).getAccount()?.userName

//function Consultants (){
const Consultants = () => {
    

    const [tableData, setTableData] = useState()
    const [addConsultantModal, setAddConsultantModal] = useState(false)
    const [Toast, setToast] = useState()
    //con-update-st
    const[{user}] = useStateValue()
    const[editConsultantModal, setEditConsultantModal] = useState(false)
    // const[updateEmailModal, setUpdateEmailModal] = useState(false)
    const[confirmModal, setConfirmModal] = useState(false)
    const[editConsultantDetails, setEditConsultantDetails] = useState()
    const[viewConsultantDetails, setViewConsultantDetails] = useState()
    const[viewConsultantModal, setViewConsultantModal] = useState(false)
    const[viewSkills, setViewSkills] = useState()
    const[viewCertificates, setViewCertificates] = useState()
    // const[updateEmailDetails, setUpdateEmailDetails] = useState()
    const[disableConsultantDetails, setDisableConsultantDetails] = useState()
    const[editSkills, setEditSkills] = useState()
    const[editCertificates, setEditCertificates] = useState()  //line added to get default certificate

    const generateActions = (consultantDetails) => {
        // console.log(DATA.Admins.includes(userName))
        //disable code added
        if( Object.keys(consultantDetails).includes('disable')){
            return <div>Consultant Disabled Contact Technical Team to restore.</div>
        } else {
            //disable code end
            return (
                    <div>
                     
                    <a title="View Profile" data-toggle="tooltip">
                        <MDBIcon icon="eye"  fixed className="black-text pr-3" onClick={ () => {viewConsultant(consultantDetails)}} />
                    </a> &nbsp; &nbsp;&nbsp;
                    {DATA.Admins.includes(userName) ? 
                    <>
                    <a title="Update Profile" data-toggle="tooltip">
                        <MDBIcon icon="edit" className="black-text pr-3" onClick={ () => {editConsultant(consultantDetails)}} />
                    </a> &nbsp;&nbsp;
                   
                   
                    <a title="Disabled Consultant" data-toggle="tooltip">
                        <MDBIcon icon="times" className="red-text pr-3" onClick={ () => {disabledConsultant(consultantDetails)}} />
                    </a>
                   
                    </>
                     :
                     <>

                     {consultantDetails.email.S === userName && 
                     <>
                        <a title="Update Profile" data-toggle="tooltip">
                        <MDBIcon icon="edit" className="black-text pr-3" onClick={ () => {editConsultant(consultantDetails)}} />
                    </a>

                   
                    </>
                    
                     }
                     </>
                     }
                </div>   
                );
            }
    }

    const getConsultantsData = async() => {
        var apiBody = {'allConsultant':'True'}
        var response = await apiCall(apiBody)
        console.log(response)
    

        var consultantsList = []
        for (var i=0; i < response.length; i++) {
            console.log(response[i].employeename.S)
            console.log(response[i])
            consultantsList.push({
                empId: response[i].employeeid.N,
                name: response[i].employeename.S,
                designation: response[i].designation.S,
                l1Manager: response[i].l1manager.S,
                l2Manager: response[i].l2manager.S,
                action: generateActions(response[i])
            })
        }
        setTableData(consultantsList)
    }

    var designationOption=[], designations = DATA.Designations
    for(var i=0; i<designations.length; i++)
        designationOption.push({
            name: designations[i],
            id: designations[i]
        })
    
    var departmentOption=[], departments = DATA.Departments
    for(var i=0; i<departments.length; i++)
        departmentOption.push({
            name: departments[i],
            id: departments[i]
        })
    
    var l1ManagerOption=[], l1Managers = DATA.L1Managers
    for(var i=0; i<l1Managers.length; i++)
        l1ManagerOption.push({
            name: l1Managers[i],
            id: l1Managers[i]
        })
    
    var l2ManagerOption=[], l2Managers = DATA.L2Managers
    for(var i=0; i<l2Managers.length; i++)
        l2ManagerOption.push({
            name: l2Managers[i],
            id: l2Managers[i]
        })
    
    var skillsOption=[], skills = DATA.Skills, levels = DATA.SkillsLevel
    for(var i=0; i<skills.length; i++)
        for(var j=0; j<levels.length; j++)
            skillsOption.push({
                name: skills[i] + " - " + levels[j],
                id: skills[i] + " - " + levels[j],
                group: skills[i]
            })
    
    var certificationOptions=[], certificates = DATA.Certificates
    for (var i=0; i<Object.keys(certificates).length; i++) {
        certificationOptions.push(
            {
                name: Object.values(certificates)[i]['Display_Short_code'] + " - " + Object.values(certificates)[i]['Name'],
                id: Object.keys(certificates)[i],
                group: certificates[i]   //added line to show certificates
            }
        );
    }

    var projectTypeOptions=[], projectTypes = DATA.ProjectTypes
    for(var i=0; i<projectTypes.length; i++)
        projectTypeOptions.push({
            name: projectTypes[i],
            id: projectTypes[i]
        })
        
    var projectPriorityOptions=[], projectPriorities = DATA.ProjectPriorities
    for(var i=0; i<projectPriorities.length; i++)
        projectPriorityOptions.push({
            name: projectPriorities[i],
            id: projectPriorities[i]
        })

    useEffect(() => {
        getConsultantsData()
    }, [])

    //con-up-st
    // var technologyOption=[], technology = DATA.Technology
    // for (var i=0; i<Object.keys(technology).length; i++) {
    //     technologyOption.push(
    //         {
    //             name: Object.values(technology)[i]['Display_Short_code'],
    //             id: Object.keys(technology)[i]
    //         }
    //         );
    // }

    const editConsultant = (consultantDetail) => {
        var unwantedItems = ["name","email","type","disabled","lastAction", "updateConsultant", "createdAt", "modifiedAt", "modifiedBy", "createdDateTime"]
        console.log(consultantDetail["experience"].S)
        var skills = Object.keys(consultantDetail).filter((obj) => {
                return unwantedItems.indexOf(obj) == -1; 
            }
            
        );
        //start-certificates code
        var certificates = Object.keys(consultantDetail).filter((obj) => {
                    return unwantedItems.indexOf(obj) == -1; 
                }
        );      
        //end certification code
        // console.log(parseFloat(consultantDetail["experience"]["N"]))
        // consultantDetail["experience"] = "" + parseFloat(consultantDetail["experience"].S)
        setEditConsultantModal(true)
        setEditSkills(skills)
        setEditCertificates(certificates) //line added to get defaULT CERTIFICATES
        setEditConsultantDetails(consultantDetail)
        console.log("SET", consultantDetail)
    }

    //view-const-start
    const viewConsultant = (consultantDetail) => {
        var unwantedskills = ["name","email","experience","designation","department","l1manager","l2manager","type","disabled","lastAction", "updateConsultant", "createdAt", "modifiedAt", "modifiedBy", "createdDateTime","submittedBy", "employeename","employeeid","certificates"]

        var unwantedCertificates= ["name","email","experience","designation","department","l1manager","l2manager","type","disabled","lastAction", "updateConsultant", "createdAt", "modifiedAt", "modifiedBy", "createdDateTime", "employeename","employeeid", "submittedBy","skills"]
        // console.log(consultantDetail)
        var skills = Object.keys(consultantDetail).filter((obj) => {
                return unwantedskills.indexOf(obj) == -1; 
            }
            
        );
        //start-certificates code
        console.log(skills)
        var skillsDisp= [],certificatesDisp=[]
        for (var i=0;i<skills.length;i++) {
            if(["Intermediate", "Beginner","Expert"].includes(skills[i].split("- ")[1])){

          
                skillsDisp.push(skills[i])
        }else{
            certificatesDisp.push(skills[i])
        }
        }
        console.log("SKILLS", skillsDisp)
        setViewConsultantModal(true)
        setViewSkills(skillsDisp)
        setViewCertificates(certificatesDisp) //line added to get defaULT CERTIFICATES
        setViewConsultantDetails(consultantDetail)
        
    }
    //view-const-end
    const disabledConsultant = (consultantDetail) => {
        setConfirmModal(true)
        setDisableConsultantDetails(consultantDetail)    
    }
    
    const disableConsultant = async (e) => {
        e.preventDefault()
        // var empId = document.getElementById('empId').value
        var apiBody = {
            "method": 'disableConsultant',
            "disableEmployee": "True",
            "employeeid": disableConsultantDetails.employeeid.N,
            // "method": 'disableConsultant',
            "modifiedBy": userName
        }
        await fetch(config.apiURL, { method: "POST", body: JSON.stringify(apiBody)})
            .then(response => response.json())
            .then(response => {
                for(var i=0;i<response.length;i++){
                    if(response[i].ResponseMetadata?.HTTPStatusCode !== 200){
                        alert('Something went wrong please contact Administrator.')
                        return
                    }
                }
                alert('Consultant Disabled Successfully')
                window.location.reload()
            })
            .catch(error => alert(error.message))
    }
    //con-up-ed

    const toolbarOptions = ['Search', 'Print'];

    const addConsultant = async(e) => {
        e.preventDefault()
        e.target.className += " was-validated";
        var empId = document.getElementById('empId').value
        var empName = document.getElementById('empName').value
        var empEmail = document.getElementById('empEmail').value
        // var designation = document.getElementById('empDesignation').value
        // var department = document.getElementById('empDepartment').value
        // var l1Manager = document.getElementById('empL1Manager').value
        // var l2Manager = document.getElementById('empL2Manager').value
        // var noOfSkills = document.getElementsByName('empSkills')[0].selectedOptions.length
        // var skillsOption = document.getElementsByName('empSkills')[0]
        // var noOfCertificates = document.getElementsByName('empCertificates')[0].selectedOptions.length
        // var certificatesOption = document.getElementsByName('empCertificates')[0]
        // var exp = document.getElementById('empExp').value
        if(empId !== '' && empName !== '' && empEmail !== '' ){
            if(empEmail.split('@')[1].includes('cloudthat.com') || empEmail.split('@')[1].includes('cloudthat.in')){
                // if(empId !== response[i].employeeid.N ){
                document.getElementsByName('btnAddProfile')[0].disabled = true
                // var skills = [], certificates = []
                // for(var i=0; i< noOfSkills; i++)
                //     skills.push(skillsOption.selectedOptions[i].value)
                // for(var i=0; i< noOfCertificates; i++)
                //     certificates.push(certificatesOption.selectedOptions[i].value)
                var apiBody = {
                    "addCTProfile": "True",
                    "employeeid": empId,
                    "email": empEmail,
                    "employeename": empName,
                    "designation": "",
                    "department": "",
                    "l1manager": "",
                    "l2manager": "",
                    // "employeeskill": skills,
                    // "certification": certificates,
                    "experience": "0.0",
                    "submittedBy": myMSALObj.getAccount().userName
                }
                var response = await apiCall(apiBody)
                console.log(response)
                if(response?.ResponseMetadata?.HTTPStatusCode === 200){

                    if (response !== null){
                    getConsultantsData()
                    
                    setAddConsultantModal(false)
                    toastShow('Success', "Profile Added Successfully", 'success')
                    }
                    else
                        toastShow('Error!', 'Enter Valid ID', 'danger') 

                
            }
                else 
                    toastShow('Error!', 'Something went wrong, Please contact Administrator.', 'danger')   
                    
            // }
            // else
            //     toastShow('Error!', 'Employee Id already exist', 'danger')
                
            } else {
                toastShow('Error!', 'Please Enter valid Email Address', 'danger')
            }
        } else {
            // alert("Please fill the all details")
            toastShow('Error!!',"Please fill all the details", 'danger')
        }
    }
    
    //con-up-const-st
    const updateConsultant = async(e) => {
        e.preventDefault()
        e.target.className += " was-validated";
        var empId = document.getElementById('empId').value
        var empName = document.getElementById('empName').value
        var empEmail = document.getElementById('empEmail').value
        var designation = document.getElementById('empDesignation').value
        var department = document.getElementById('empDepartment').value
        var l1Manager = document.getElementById('empL1Manager').value
        var l2Manager = document.getElementById('empL2Manager').value
        var noOfSkills = document.getElementsByName('empSkills')[0].selectedOptions.length
        var skillsOption = document.getElementsByName('empSkills')[0]
        var noOfCertificates = document.getElementsByName('empCertificates')[0].selectedOptions.length
        var certificatesOption = document.getElementsByName('empCertificates')[0]
        var exp = document.getElementById('empExpYears').value+"."+document.getElementById("empExpMonths").value
        if( designation !== '' && department !== '' && l1Manager !== '' && noOfSkills > 0 && exp !== '' ){
            // if(empEmail.split('@')[1].includes('cloudthat.com') || empEmail.split('@')[1].includes('cloudthat.in')){

                document.getElementsByName('updateConsultant')[0].disabled = true    
                if (exp.split(".")[1]<12) {

                var skills = [], certificates = []
                for(var i=0; i< noOfSkills; i++)
                    skills.push(skillsOption.selectedOptions[i].value)
                for(var i=0; i< noOfCertificates; i++)
                    certificates.push(certificatesOption.selectedOptions[i].value)
                var apiBody = {
                    "updateCTProfile": "True",
                    "employeeid": empId,
                    "email": empEmail,
                    "employeename": empName,
                    "designation": designation,
                    "department": department,
                    "l1manager": l1Manager,
                    "l2manager": l2Manager,
                    "employeeskill": skills,
                    "certification": certificates,
                    "experience": exp,
                    "submittedBy": myMSALObj.getAccount().userName
                }
                var response = await apiCall(apiBody)
                console.log(response)
                if(response.ResponseMetadata.HTTPStatusCode === 200){
                    getConsultantsData()
                    setEditConsultantModal(false)
                    toastShow('Success', "Profile Updated Successfully", 'success')
                    // window.location.reload()  
                }
                else 
                    toastShow('Error!', 'Please enter valid Id or contact Administrator.', 'danger')
            //  else {
            //     toastShow('Error!', 'Please Enter valid Email Address', 'danger')
            // }
            }
            else{
                toastShow("Error!!", "Experience months should be less than 12", 'danger')
            }
        } else {
            // alert("Please fill the all details")
            toastShow('Error!!',"Please fill all the details", 'danger')
        }
    }
    //con-update-cons-ed
    
    //con-up-st
    const statusTemplate = (props) => {
        if(props.status === false)
            return(
                <div className="statustemp e-activecolor">
                    <span className="statustxt e-activecolor">Active</span>
                </div>
            )
        else 
            return (
                <div className="statustemp e-inactivecolor">
                    <span className="statustxt e-inactivecolor">Inactive</span>
                </div>
            )
       
    }
    //con-up-end

    const toastShow = (title, text, style) => {
        setTimeout(() => {
            Toast.target = '#mainDiv'
            Toast.show( {title: `${title}`, content: text, cssClass: `e-toast-${style}`});
        }, 200);
    }
    const l1change = (args) =>{
        if(args.value === "Bhavesh Goswami")
            {document.getElementById('empL2Manager').disabled = true
            document.getElementById('empL2Manager').value = "None"
            // document.getElementById('projPriorities').value =""
    }
    else document.getElementById('empL2Manager').value = ""
    }

    return (
        <div style={{ marginTop: '2%', backgroundColor:""}} id="mainDiv">
        {DATA.Admins.includes(userName) &&
            <MDBContainer>
                <MDBRow end>
                    <MDBBtn type="button" id="btnColor" className="pull-right" style={{zIndex:3}} onClick={() => setAddConsultantModal(true)}> Add Consultant</MDBBtn>
                </MDBRow>
            </MDBContainer>
        }
            <div style={{textAlign:"center"}} className=" col-md-12 col-sm-12 col-xs-12 ">
                <GridComponent
                    dataSource={tableData}
                    toolbar={toolbarOptions}
                    allowPaging={true}
                    pageSettings={{pageSize: 50,pageSizes: [50,100,150,200], pageCount: 5}}
                    allowTextWrap={true}
                    showColumnChooser={true}
                    allowExcelExport={true}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowPdfExport={true}
                    allowResizing={true}
                    searchSettings={
                        {fields: ['empId', 'name', 'designation', 'l1Manager', 'l2Manager'],
                        ignoreCase: true,
                        operator: 'contains'}
                    }
                    sortSettings={{columns: [{ field: 'name', direction: 'Ascending' }]}}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='empId' headerText='ID' textAlign='Left' width={60}/>
                        <ColumnDirective field='name' headerText='Name' textAlign='Left'/>
                        <ColumnDirective field='designation' headerText='Designation' textAlign='Left'/>
                        <ColumnDirective field='l1Manager' headerText='L1 Manager' textAlign='Left'/>
                        <ColumnDirective field='l2Manager' headerText='L2 Manager' textAlign='Left'/>
                        {/* {DATA.Admins.includes(userName) && */}
                        <ColumnDirective field='action' headerText='Action' headerTextAlign={'Center'} template={(props) => {return props.action}}/>
                        {/* } */}
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Page, Sort]}/>
                </GridComponent>
            </div>
            <MDBModal isOpen={addConsultantModal} toggle={() => setAddConsultantModal(false)} size="lg" centered>
                <form  className="needs-validation" onSubmit={addConsultant} noValidate id="addConultantForm" >
            
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true" onClick={() => setAddConsultantModal(false)}>×</button>
                    <MDBModalHeader>Add Consultant Profile
                    </MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol md="2">
                                <div className="form-group">
                                    <label htmlFor="empId" className="mb-0">Employee ID</label>
                                    <input className="form-control" type="number" required id="empId" placeholder="" max="9999" min="5000"/>
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="empName" className="mb-0">Employee Name</label>
                                    <input className="form-control" type="text" required id="empName" placeholder="Enter Employee Name" />
                                </div>
                            </MDBCol>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="empEmail" className="mb-0">Employee Email</label>
                                    <input className="form-control" type="email" required id="empEmail" placeholder="Enter Employee Email" />
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn type="submit" id="btnColor" name="btnAddProfile" className="pull-right" style={{zIndex:3}}> Add Profile</MDBBtn>
                    </MDBModalFooter>
                </form>
                
            </MDBModal>
            
            <ToastComponent
                ref={toast => setToast(toast)}
                position={ {X: 'Right', Y: 'Top' }}
            />
            {/* con-up-st */}

            <MDBModal isOpen={editConsultantModal} toggle={() => {setEditConsultantModal(false)}} id="edit"  size="lg" centered  >
                <form className="needs-validation" onSubmit={updateConsultant} noValidate >
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" onClick={() => setEditConsultantModal(false)}>×</button>
                    <MDBModalHeader id="modalHeader" >Update Profile</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor='empId' className="mb-0">Employee ID</label>
                                    <input className="form-control" type="number" required autoComplete="off" id="empId" defaultValue={editConsultantDetails?.employeeid.N} placeholder="Enter Full Name" name="nfullname" disabled required />
                                </div>
                            </MDBCol>   
                       
                        
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor='empId' className="mb-0">Employee Name</label>
                                    <input className="form-control" type="text" required autoComplete="off" id="empName" defaultValue={editConsultantDetails?.employeename.S} placeholder="Enter Full Name" name="nfullname"  required />
                                </div>
                            </MDBCol>   
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="empEmail" className="mb-0">Employee Email:</label>
                                    <input className="form-control" type="email" id="empEmail" autoComplete="off"  name="email" defaultValue={editConsultantDetails?.email.S} disabled required/>
                                </div>
                            </MDBCol>
                        
                            <MDBCol >
                                <div className="form-group"  >
                                    <label htmlFor="empDesignation" className="mb-0" >Designation</label>
                                    <DropDownListComponent 
                                        id="empDesignation"
                                        dataSource={designationOption}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Designation"
                                        sortOrder="Ascending"
                                        required={true}
                                        value={editConsultantDetails?.designation.S}
                    
                                    />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol >
                                <div className="form-group">
                                <label htmlFor="empDepartment" className="mb-0" >Department</label>
                                    <DropDownListComponent
                                        id="empDepartment"
                                        dataSource={departmentOption}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Department"
                                        sortOrder="Ascending"
                                        value={editConsultantDetails?.department.S}
                                    />
                                </div> 
                            </MDBCol>
                        
                            <MDBCol >
                                <div className="form-group" className="mb-0" >
                                <label htmlFor="empL1Manager" className="mb-0">Level-1 Manager</label>
                                    <DropDownListComponent
                                        id="empL1Manager"
                                        dataSource={l1ManagerOption}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Level-1 Manager"
                                        sortOrder="Ascending"
                                        value={editConsultantDetails?.l1manager.S} 
                                        change={l1change}       
                                        />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol  md="6">
                                <div className="form-group" id="skillsContainer" >
                                <label htmlFor="empL2Manager" className="mb-0">Level-2 Manager</label>
                                    <DropDownListComponent
                                        id="empL2Manager"
                                        dataSource={l2ManagerOption}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Select Level-2 Manager"
                                        sortOrder="Ascending"
                                        value={editConsultantDetails?.l2manager.S}
                                    />
                                </div>

                            </MDBCol>
                            <MDBCol md="6">
                    <label for = "experience"  className="mb-0">
                  <span> Experience  </span>
                    <MDBRow>
                      <MDBCol  className="mb-0">Years
                        <div className="form-group">
                          <input className="form-control" type="number" id="empExpYears" min="0" max = "40" name="Experience1"  defaultValue={editConsultantDetails?.experience.S.split(".")[0]} />
                        </div>
                      </MDBCol>
                      <MDBCol  className="mb-0">Months
                        <div className="form-group">
                          <input className="form-control" type="number" id="empExpMonths" min="0" max="11" name="Experience2" defaultValue={editConsultantDetails?.experience.S.split('.')[1]}/>
                        </div>
                      </MDBCol>
                    </MDBRow>
                </label></MDBCol>
                </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                <label htmlFor="empSkills" className="mb-0">Employee Skills</label>
                                    <MultiSelectComponent
                                        id="empSkills"
                                        dataSource={skillsOption}
                                        fields={{text: 'name', value: 'id', groupBy: 'group'}}
                                        placeholder="Select Employee Skills"
                                        sortOrder="Ascending"
                                        mode="CheckBox"
                                        showSelectAll="true"
                                        value={editSkills}
                                    >
                                        <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent>
                                </div>
                            </MDBCol>
  
                            <MDBCol>
                                <div className="form-group" >
                                <label htmlFor="empSkills" className="mb-0" >Employee Certificates</label>
                                    <MultiSelectComponent
                                        id="empCertificates"
                                        dataSource={certificationOptions}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Select Employee Certificates"
                                        sortOrder="Ascending"
                                        mode="CheckBox"
                                        showSelectAll="true"
                                        value={editCertificates}
                                        >
                                        <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent>
                                </div>
                            </MDBCol>
                        </MDBRow>
    
                    </MDBModalBody>
                    <MDBModalFooter>
                            <MDBBtn block type="submit" name="updateConsultant" id="btnColor" style={{ backgroundColor: "#2f3d5e !important" }}> Update Consultant </MDBBtn>
                    </MDBModalFooter>
                </form>
            </MDBModal>
            <MDBModal isOpen={confirmModal} toggle={() => {setConfirmModal(false)}} centered>
                <MDBModalHeader>Are Sure?</MDBModalHeader>
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol>Are sure to disable this Consultant?</MDBCol>
                    </MDBRow>
                </MDBModalBody>
                <MDBModalFooter>
                    <form onSubmit={disableConsultant}>
                        <MDBRow>
                            <MDBCol md="6">
                                <MDBBtn type="submit" color="success">Yes</MDBBtn>
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBBtn color="red" onClick={() => {setConfirmModal(false)}}>No</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>
                </MDBModalFooter>
            </MDBModal>
            

            {/* //con-up-ed */}

            {/* view consultant start */}
            
            <MDBModal isOpen={viewConsultantModal} toggle={() => {setViewConsultantModal(false)}} id="edit"  size="lg" centered  >
                <form className="needs-validation" onSubmit={viewConsultant} noValidate >
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" onClick={() => setViewConsultantModal(false)}>×</button>
                    <MDBModalHeader id="modalHeader" >View Profile</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor='empId' className="mb-0">Employee ID</label>
                                    <input className="form-control" type="number" required autoComplete="off" id="empId" defaultValue={viewConsultantDetails?.employeeid.N} placeholder="Enter Full Name" name="nfullname" disabled required />
                                </div>
                            </MDBCol>   
                       
                        
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor='empId' className="mb-0">Employee Name</label>
                                    <input className="form-control" type="text" required autoComplete="off" id="empName" defaultValue={viewConsultantDetails?.employeename.S} placeholder="Enter Full Name" name="nfullname"  disabled required />
                                </div>
                            </MDBCol>   
                        </MDBRow>
                        <MDBRow>
                            <MDBCol>
                                <div className="form-group">
                                    <label htmlFor="empEmail" className="mb-0">Employee Email:</label>
                                    <input className="form-control" type="email" id="empEmail" autoComplete="off"  name="email" defaultValue={viewConsultantDetails?.email.S} disabled required/>
                                </div>
                            </MDBCol>

                            <MDBCol >
                            
                                <div className="form-group">
                                    <label htmlFor='empDesignation' className="mb-0">Designation</label>
                                    <input className="form-control" type="text" required autoComplete="off" id="empDesignation" defaultValue={viewConsultantDetails?.designation.S}  disabled required />
                                </div>
                            </MDBCol>
                        
                        </MDBRow>
                        <MDBRow>
                            <MDBCol >
                            
                                <div className="form-group">
                                    <label htmlFor='empDepartment' className="mb-0">Department</label>
                                    <input className="form-control" type="text" required autoComplete="off" id="empDepartment" defaultValue={viewConsultantDetails?.department.S}  disabled required />
                                </div>
                            </MDBCol>  
                        
                          

                            <MDBCol >
                            
                                <div className="form-group">
                                    <label htmlFor='empL1Manager' className="mb-0">Level-1 Manager</label>
                                    <input className="form-control" type="text" required autoComplete="off" id="empL1Manager" defaultValue={viewConsultantDetails?.l1manager.S}  disabled required />
                                </div>
                            </MDBCol> 
                            
                        </MDBRow>
                        <MDBRow>

                        <MDBCol>
                            
                                <div className="form-group">
                                    <label htmlFor='empL2Manager' className="mb-0">Level-2 Manager</label>
                                    <input className="form-control" type="text" required autoComplete="off" id="empL2Manager" defaultValue={viewConsultantDetails?.l2manager.S}  disabled required />
                                </div>
                            </MDBCol>  
                            
                            <MDBCol md="6">
                    <label for = "experience"  className="mb-0">
                  <span> Experience  </span>
                    <MDBRow>
                      <MDBCol  className="mb-0">Years
                        <div className="form-group">
                          <input className="form-control" type="number" id="empExpYears" min="0" max = "40" name="Experience1"  defaultValue={viewConsultantDetails?.experience.S.split(".")[0]} disabled required />
                        </div>
                      </MDBCol>
                      <MDBCol  className="mb-0">Months
                        <div className="form-group">
                          <input className="form-control" type="number" id="empExpMonths" min="0" max="11" name="Experience2" defaultValue={viewConsultantDetails?.experience.S.split(".")[1]} disabled required/>
                        </div>
                      </MDBCol>
                    </MDBRow>
                </label></MDBCol>
                </MDBRow>
                        <MDBRow>
                        <MDBCol>
                        {/* <div className="form-group"> */}
                        <label htmlFor='empSkills' className="mb-0">Employee Skills</label>
                            
                        <ListViewComponent
                            id="empSkills"
                            dataSource={viewSkills}
                            sortOrder="Ascending"
                            style={{ display: "block",
                                    maxWidth: "400px",
                                    margin: "auto",
                                    border: "1px solid #dddddd",
                                    borderRadius: "3px"}}
                            // value={viewSkills}
                            disabled
                         />

                         {/* </ListViewComponent> */}
                         {/* </div> */}

                            </MDBCol>

                            <MDBCol>
                            <div className="form-group">
                            <label htmlFor='empCertificates' className="mb-0">Employee Certificates</label>
                            <ListViewComponent
                            id="empCertificates"
                            dataSource={viewCertificates}
                            style={{ display: "block",
                                    maxWidth: "400px",
                                    margin: "auto",
                                    border: "1px solid #dddddd",
                                    borderRadius: "3px"}}
                            sortOrder="Ascending"
                            showSelectAll="true"
                            // value={editCertificates}
                            >

                         </ListViewComponent>
                         </div>
                         </MDBCol>
                         </MDBRow>
    
                    </MDBModalBody>
                   
                </form>
            </MDBModal>
            
            {/* view consultant end */}
        </div>
        
    )
}

export default Consultants
