import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, Filter, GridComponent, Page, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact'
import React, { useState } from 'react'
import apiCall from '../apiCall';
import DATA from '../assets/data.json'

function Resources() {

    const [ tableData, setTableData ] = useState()

    var certificationOptions=[], certificates = DATA.Certificates
    for (var i=0; i<Object.keys(certificates).length; i++) {
        certificationOptions.push(
            {
                name: Object.values(certificates)[i]['Display_Short_code'],
                id: Object.keys(certificates)[i]
            }
        );
    }

    var skillsOption=[], skills = DATA.Skills
    for(var i=0; i<skills.length; i++)
        skillsOption.push({
            name: skills[i],
            id: skills[i]
        })

    const onSubmit = async (e) => {
        e.preventDefault()
        var noOfCertificates = document.getElementsByName('certificates')[0].selectedOptions.length
        var certificatesOption = document.getElementsByName('certificates')[0]
        var noOfSkills = document.getElementsByName('skills')[0].selectedOptions.length
        var skillsOption = document.getElementsByName('skills')[0]
        var certificates = [], skills = []
        for(var i=0; i< noOfCertificates; i++)
            certificates.push(certificatesOption.selectedOptions[i].value)
        for(var i=0; i< noOfSkills; i++)
            skills.push(skillsOption.selectedOptions[i].value)

        var apiBody = {
            "displaySkillsforconsulting": "True",
            "skills": skills,
            "certificate": certificates
        }
        const response = await apiCall(apiBody)
        var consultantsList = []
        for (var i=0; i < response.length; i++) {
            consultantsList.push({
                name: response[i].Employeename,
                project: response[i].Projectname,
                exp: response[i].Experience
            })
        }
        setTableData(consultantsList)
    }

    return (
        <div style={{ marginTop: '3%', backgroundColor:""}}>
            <div className="m-3">
                <MDBRow center>
                    <MDBCol md="8">
                        <MDBRow>
                            <MDBCol md="5">
                                <div className="form-group">
                                    <label>Certificates</label>
                                    <MultiSelectComponent
                                        id="certificates"
                                        dataSource={certificationOptions}
                                        mode="CheckBox"
                                        showSelectAll={true}
                                        fields={{text: 'name',value: 'id'}}
                                        placeholder="Select Certificates"
                                    >
                                        <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent>
                                </div>
                            </MDBCol>
                            <MDBCol md="5">
                                <div className="form-group">
                                    <label>Skills</label>
                                    <MultiSelectComponent
                                        id="skills"
                                        dataSource={skillsOption}
                                        mode="CheckBox"
                                        showSelectAll={true}
                                        fields={{text: 'name', value: 'id'}}
                                        placeholder="Select Skills"
                                    >
                                        <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent>
                                </div>
                            </MDBCol>
                            <MDBCol md="2">
                                <MDBBtn size="lg" block type="button" class="btn Ripple-parent btn-default" id="btnColor" style={{ backgroundColor: "#2f3d5e !important"}} onClick={onSubmit}> Submit </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
            <div className="m-3">
                <MDBRow center>
                <GridComponent
                    width={"80%"}
                    dataSource={tableData}
                    toolbar={['Search', 'Print']}
                    allowPaging={true}
                    pageSettings={{pageSize: 50,pageSizes: [50,100,150,200], pageCount: 5}}
                    allowTextWrap={true}
                    allowFiltering={true}
                    showColumnChooser={true}
                    allowExcelExport={true}
                    allowSorting={true}
                    allowMultiSorting={true}
                    allowPdfExport={true}
                    allowResizing={true}
                    searchSettings={
                        {fields: ['name', 'project', 'exp'],
                        ignoreCase: true,
                        operator: 'contains'}
                    }
                    sortSettings={{columns: [{ field: 'name', direction: 'Ascending' }]}}
                    filterSettings={{type: 'CheckBox'}}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='name' headerText='Name' textAlign='Left'/>
                        <ColumnDirective field='project' headerText='Project' textAlign='Left'/>
                        <ColumnDirective field='exp' headerText="Experience" textAlign='Left' />
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Page, Sort, Filter]}/>
                </GridComponent>
                </MDBRow>
            </div>
        </div>
    )
}

export default Resources
